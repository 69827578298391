import { DrawerEvent } from "../enums/event";

import dispatchEvent from "../utils/dispatch-event";

class DrawerToggle extends HTMLElement {
  toggle;

  constructor() {
    super();

    this.toggle = this.querySelector("[data-drawer-toggle]") as HTMLElement;

    this.registerEvents();
  }

  getDrawerName() {
    return this.toggle.dataset.drawer;
  }

  registerEvents() {
    this.toggle.addEventListener("click", () => {
      dispatchEvent(DrawerEvent.Toggle, this.toggle, {
        detail: {
          drawerName: this.getDrawerName(),
        },
      });
    });
  }
}

customElements.define("drawer-toggle", DrawerToggle);
